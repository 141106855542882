// const middlewares = {
//   getContents: {
//     before: [
//       function (context, route) {
//         // this refers to global store context
//         return Promise.resolve()
//       }
//     ],
//     after: []
//   }
// }

import { getCurrentContent } from '~/helper-rotative-contents'

export default () => {
  return {
    state: {
      defaultLoadedContents: [],
    },
    actions: {
      setDefaultLoadedContents({ commit }, contents) {
        commit('SET_DEFAULT_LOADED_CONTENTS', contents)
      },
    },
    mutations: {
      // eslint-disable-next-line
      ['SET_DEFAULT_LOADED_CONTENTS'](state, contents) {
        state.defaultLoadedContents = [...contents]
      },
    },
    middlewares: {
      getContents: {
        success: [
          function (context, route) {
            this.timer = setInterval(() => {
              const content = getCurrentContent(
                context.state.defaultLoadedContents
              )

              context.dispatch(
                'player/changePlayerContent',
                {
                  content,
                  noRedirect: false,
                },
                { root: true }
              )

              if (context.rootGetters['player/isPlaying'] === true) {
                context.dispatch('player/ctrlPlayer', 'play', { root: true })
              }
            }, 10000)
          },
        ],
      },
    },
  }
}
