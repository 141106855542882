const colors = require('vuetify/es5/util/colors').default

const config = {
  /**
   * @type {String}
   * Brocoli Site ID, required
   */
  siteId: process.env.BROCOLI_SITE_ID,

  /**
   * @type {Object}
   * app properties, overridable via config or brocoli
   * site metadatas (slug is the property)
   */
  metadatas: {
    /**
     * @type {String}
     * the application name
     */
    name: `Bubblecast`,

    /**
     * @type {String}
     * rgba or hexadecimal value used as backgroundColor by the
     * global app loader
     * if undefined (default), bubblecast will use the `colorPrimary`
     * value
     */
    loadingColor: undefined,
    /**
     * @type {String}
     * rgba or hexadecimal value used as backgroundColor by the
     * screen loader (in webapp mode)
     * if undefined (default), bubblecast will use the `colorPrimary`
     * value
     */
    webappThemeColor: undefined,
    /**
     * @type {String}
     * the manifest lang property (default: fr-FR)
     */
    lang: 'fr-FR',
    /**
     * @type {String}
     * the head meta charset property (default: utf-8)
     */
    charset: 'utf-8',
    /**
     * @type {String}
     * the theme.vuetify.primary color, if defined here
     * will override the theme.vuetify.primary color
     */
    colorPrimary: '#e84b23',
    /**
     * @type {String}
     * the theme.vuetify.accent color, if defined here
     * will override the theme.vuetify.accent color
     */
    colorAccent: '#ededed',
    colorSecondary: colors.amber.darken3,
    colorInfo: colors.teal.lighten1,
    colorWarning: colors.amber.base,
    colorError: colors.deepOrange.accent4,
    colorSuccess: colors.green.accent3,

    /**
     * @type {String}
     * Name of application logo
     * should be located in /assets or /statics
     */
    iconFileName: 'icon.png',
    iconProperties: {},

    /**
     * @type {String}
     * Technical support email address used if
     * user can't access properly to the webapp
     */
    supportEmailAddress: 'digital@mediameeting.fr',
  },

  analytics: {
    /**
     * @type {string}
     * Id of the google analytics app
     */
    ga: process.env.GA_ID || undefined,
    /**
     * @type {Boolean}
     * Active the User ID google analytics feature
     * If you wan't this feature, you must have customer
     * privacy agreement and explicitely activate this
     * feature on GA
     * See documentation about user id here:
     * https://support.google.com/analytics/answer/3123666
     */
    gaFeatureUid: true,
    /**
     * @type {string}
     * Token to track events on mix panel
     */
    mixpanel: process.env.MIXPANEL_TOKEN || undefined,
    /**
     * @type {string}
     * Sentry DSN
     */
    sentry: process.env.SENTRY_DSN || undefined,
  },

  /**
   * @type {Array<String>}
   * @type {Array<Array[name: string, options: object]>}
   * List of the auth modes that can be used
   * to authenticate for this site
   * Supported types are : 'local', 'providers'
   * If local is passed, you can override default options as:
   *
   * @example
   * ```
   * authMode: [
   *   ['local', {
   *     // @default 'default'
   *     // @param 'default' : default login (username / password)
   *     // @param 'unique_password' : shared account (user set only a generic password)
   *     // @param 'otp' : one time password (a short token is sent by mail / sms)
   *     // @type {string}
   *     // if mode = 'unique_password' you must indicate the `defaultLogin` property
   *     mode: 'default'
   *   }]
   * ]
   * ```
   */
  authMode: [],

  /**
   * @type {Boolean}
   * if `true` a checkbox _should_ be checked
   * to perform a `login action`
   * this checkbox force user to aprove legals
   * to perform the connection
   */
  authOptInRequired: false,

  /**
   * @type {Boolean}
   * if `true` will override the `site.optRegistrable` property
   * to render the connection form
   * usefull in development mode
   * if this option is `true` in a production mode, an error
   * will be thrown
   */
  authForceLocaleForDevelopment: false,

  /**
   * @type {Array<String|Regex>}
   * perform a regex check of the login entry in locale
   * registration / connection
   * can be : phone, email or a personal regex
   * @example
   * ['email', 'phone', /^[a-zA-Z0-9_]*$/]
   */
  authLocaleLoginFormat: ['email'],

  /**
   * @type {Array[Array<Provider, Options>]}
   * list of supported providers on the current application
   * currently supported providers :
   *  _ WSSOAuth0
   *  _ custom sso providers
   *
   * @usage
   * [String: provider, Object: options]
   * @example
   * [
   *   ['WSSOAuth0', {
   *     // see ~/components/WSSO/WSSOAuth0/Readme.md
   *     // for configurables options
   *   }],
   *   // you can use your own wssocomponent (created in the WSSO folder)
   *   // as below :
   *   ['WSSOExample', {
   *     // options
   *   }]
   * ]
   *
   * _google provider options_
   * @param {Object} options
   * @param {Object} options.signInOptions
   * @param {Object} options.signInParams
   */
  authOAuthProviders: [],

  /**
   * @type {Boolean}
   * if `true`, the player can't be closed
   */
  closablePageListen: false,

  /**
   * @type {Boolean}
   * if `true`, player is not filled with
   * the last available content
   */
  disableAutosetPlayerContent: false,

  /**
   * @type {Boolean}
   * if `true`, the navigation items are not
   * defined as `active` based on route.mode
   * but use native router path algorithm
   */
  disableRouterActivelinkComputationBasedOnMode: false,

  /**
   * @type {Boolean}
   * if `true`, cards are not clickable and user
   * cannot access to the listen page
   */
  disablePageListen: false,

  /**
   * @type {String}
   * Add a `yyyy-mm` (month is padded to 2) string here to allow datepicker
   * selection from this entry time
   * @example
   * "2019-04"
   */
  datePickerStartAt: null,

  /**
   * @type {Array<String>}
   * list of disabled components
   * currently supported:
   * - wAuthenticatorPasswordReset
   * - wButtonLike
   * - wToolbarSearch
   */
  disabledComponents: [],

  /**
   * @type {Array<Object>}
   * list of special routes (availables in the topbar / bottombar)
   * @param {String} title
   * @param {String} slug
   * @param {String} icon
   * @param {Object} options
   * @param {Object} options.filter : boolean or array of tag slugs
   * @param {Boolean} options.highlighted
   * @param {Boolean} options.visibleInMenu
   * @param {Object} options.payload
   * @param {String} options.payload.is
   * @param {Function} options.reducer
   * ```js
   * function reducer (contents, storeContext) {
   *   // where `contents` is a spoke `PseudoArray`
   *   // and `storeContext` presents the contents light store
   *   // contains `state`, `getters` and `rootState`
   *   return contents.toArray()
   * }
   * ```
   * @param {Object} router
   * @param {String} router.path
   * @param {String} router.mode
   *
   * @documentation
   * Override the `pages` configuration with `voicerconfig/page`
   *
   * @example
   * pages: (pager) => {
   *   return pager
   *    .remove('radios')
   *    .add({
   *      title: 'Lives',
   *      slug: 'streams',
   *      icon: 'live-tv',
   *      options: {},
   *      router: {}
   *    })
   *    .update('home', (page) => {
   *      page.setReducer((content) => {
   *        return content.toArray().filter(content => content.slug === 'home')
   *      })
   *    })
   * }
   */
  basePages: [
    {
      title: 'Accueil',
      slug: 'default',
      icon: 'home',
      options: {
        filter: true,
        highlighted: true,
        payload: {
          is: 'podcast',
        },
      },
      visibleInMenu: false,
      router: {
        path: '/',
      },
    },
    {
      title: 'Podcasts',
      slug: 'podcasts',
      icon: 'library_music',
      options: {
        filter: true,
        highlighted: true,
        payload: {
          is: 'podcast',
        },
      },
      router: {
        path: '/',
      },
    },
    {
      title: 'Radios',
      slug: 'radios',
      icon: 'radio',
      options: {
        filter: true,
        highlighted: true,
        payload: {
          is: 'live',
        },
        /**
         * store reducer example
         */
        reducer: function reducer(contents, storeContext) {
          return contents.toArray()
        },
      },
      router: {
        path: '/',
        mode: 'radios',
      },
    },
    {
      title: 'Search',
      slug: 'search',
      icon: null,
      options: {
        filter: true,
        highlighted: false,
        payload: {},
      },
      visibleInMenu: false,
      router: {
        path: '/',
        mode: 'search',
      },
    },
    {
      title: 'Filters',
      slug: 'filters',
      icon: null,
      options: {
        filter: true,
        highlighted: false,
        payload: {},
      },
      visibleInMenu: false,
      router: {
        path: '/',
        mode: 'filters',
      },
    },
  ],

  /**
   * @type {Function}
   * Array function who returns drawer navigation
   * menu
   * Titles should i18n keys stored in components/WNavigationDrawer/index.vue <i18n> block
   */
  drawerNavigation: () => [
    {
      type: 'link',
      icon: 'mdi-cog-outline',
      value: '/settings',
      title: (ctx) => ctx.$t('settings'),
    },
    {
      icon: 'mdi-information-outline',
      value: 'info',
      title: (ctx) => ctx.$t('app_info'),
      noAction: true,
      disableAutoClose: true,
      items: [
        {
          type: 'link',
          title: (ctx) => ctx.$t(`legal_notice`),
          value: '/data/legal-notice',
        },
        {
          type: 'link',
          title: (ctx) => ctx.$t(`terms_usage_policy`),
          value: '/data/terms-of-service',
        },
        {
          type: 'link',
          title: (ctx) => ctx.$t(`confidentiality_policy`),
          value: '/data/personal-data',
        },
        {
          type: 'method',
          title: (ctx) => ctx.$t(`versions`),
          value: 'versions',
          onClick: (context) => {
            context.dialogVersions = true
          },
        },
      ],
    },
    {
      condition(context) {
        return context.$spoke.site.optAuthentication === true
      },
      type: 'method',
      icon: 'mdi-exit-to-app',
      value: 'logout',
      title: (ctx) => ctx.$t('logout'),
      onClick: async (context) => {
        await context.$store.dispatch('auth/logout')
      },
    },
  ],

  /**
   * @type {Function}
   * @description
   * a way to display (or not) a filter in the
   * filters component
   */
  filterVisibleFilters({ page, locale }) {
    return (filter) => true
  },

  /**
   * @type {Object}
   * pass an object here to override ENV
   * configuration of spoke
   */
  forceSpokeConfiguration: undefined,

  /**
   * Support of i18n
   * Locales currently supported : en & fr
   *
   * I18n Configurations:
   *  - page titles can be multi lang: title: 'XXX' -> title: { fr: 'frXXX', en: 'enXXX' }
   *
   * To add a new language :
   *  - edit i18n tags in components
   *  - create corresponding modules/i18n/messages/i18n/_locale_.json
   *  - add locale in util/dates.js
   */
  i18n: {
    locales: ['fr'],
    defaultLocale: 'fr',
  },

  /**
   * @type {Boolean}
   * if `true`, you can create `vue` block
   * and render customizable components and datas
   * @see /blocks directory
   */
  enableExperimentalBlocks: true,

  /**
   * @type {Number}
   * @default 9
   * limit api queries to the N firsts results from the current offset
   * (automatically computed via spoke)
   */
  paginationLimit: 9,

  /**
   * @type {String}
   * @default false
   * will redirect user homepage to `mode`
   */
  redirectHomepageToMode: false,

  /**
   * @type {String | Function}
   * @default '/'
   * will redirect user to the given path (default is root)
   */
  redirectSearchTo: '/',

  /**
   * @type {Boolean}
   * @default false
   * will display the content summary in contents list (wvoicy list)
   */
  showCardDescription: false,

  /**
   * @type {String}
   * @type {Array[name, options]}
   * @default null
   * If defined, will override default theme (here called shell)
   * use array notation to override default theme properties
   * (see shell readme)
   */
  useShell: undefined,

  /**
   * @type {Object}
   * @description
   * will override the default `nuxt.config.js` configurations
   * @doc https://nuxtjs.org/guide/configuration
   */
  nuxt: {
    /**
     * markdown init module configuration
     * https://github.com/nuxt-community/modules/tree/master/packages/markdownit
     */
    markdownit: {
      preset: 'default',
      html: true,
      linkify: true,
      breaks: true,
      typographer: true,
      use: [
        [
          '@toycode/markdown-it-class',
          {
            h1: 'display-3',
            h2: 'display-2',
            h3: 'title',
            h4: 'headline',
            h5: 'subheading',
            p: 'body-2',
          },
        ],
        'markdown-it-container',
        'markdown-it-attrs',
      ],
    },
    /*
     ** vuetify module configuration
     ** https://github.com/nuxt-community/vuetify-module
     */
    vuetify: {
      iconfont: 'mdi',
      materialIcons: false,
      threeShake: true,
      defaultAssets: {
        font: false,
        icons: 'mdiSvg',
      },
      theme: {
        primary: '#e84b23',
        accent: '#ededed',
        secondary: colors.amber.darken3,
        info: colors.teal.lighten1,
        warning: colors.amber.base,
        error: colors.deepOrange.accent4,
        success: colors.green.accent3,
      },
    },
  },

  /**
   * @type {Function}
   * @description
   * Apply some route directive to the `$route.push` (see vue-router)
   * method called when an user set a tag on filters
   * @returns {Object}
   * @see (store:filters) #saveDateQuery
   * @see (store:filters) #saveTagQuery
   */
  overrideRouteDestFilters: (context) => {
    return {}
  },

  /**
   * @type {Object}
   * @todo document and example
   */
  routes: {
    publicRoutes: [
      'login',
      'login-redirect',
      'login-register',
      'data-id',
      /password_reset-?/,
      'register',
      'register-activate',
      'settings',
      'support',
    ],
    store: {
      'contents/getContents': ['index'],
      'contents/getContent': ['listen-content'],
      'filters/getCategories': ['index'],
    },
  },

  /**
   * @type {Object}
   */
  theme: {
    /**
     * @type {Object}
     * See https://google-fonts.nuxtjs.org/options
     */
    fonts: {
      families: {
        Roboto: [300, 400, 500, 700],
        Merriweather: [300, 400, 500, 700],
      },
      download: true,
      outputDir: 'static/fonts/google',
      fontsPath: '~static/fonts/google/fonts',
    },
    /**
     * @type {function}
     * A function with the app context as argument
     * You must return an object with two properties :
     * - mobile {string}
     * - desktop {string}
     * One is applied depending context.isMobile boolean
     * on the highlighted component
     */
    imageGradient: (context) => {
      return {
        mobile:
          context.app.$voicer.isDarkTheme === true
            ? `to bottom, rgba(33,33,33,0.0) 0%, rgba(33,33,33,0.75) 40%, rgba(33,33,33,1) 100%`
            : `to bottom, rgba(250,250,250,0.0) 0%, rgba(250,250,250,0.75) 40%, rgba(250,250,250,1) 100%`,
        desktop:
          context.app.$voicer.isDarkTheme === true
            ? `to bottom, rgba(33,33,33,0.0) 0%, rgba(33,33,33,0.75) 60%, rgba(33,33,33,1) 100%`
            : `to bottom, rgba(250,250,250,0.0) 0%, rgba(250,250,250,0.75) 40%, rgba(250,250,250,1) 100%`,
      }
    },
    /**
     * @type {Object}
     * A property can be an object (style), a value (number) or a function
     * where the first argument is the context
     * @examples
     * height: (context) => context.isMobile ? 54 : 64
     * height: 54
     *
     * @notes
     * device properties are availables over device module : https://github.com/nuxt-community/device-module
     */
    components: {
      aside: {
        style: {},
      },
      card: {
        aspectRatio: 1.778,
        style: {},
      },
      player: {
        backgroundColor: (context) => {
          return context.isDarkTheme === true ? `#212121` : `#f6f6f6`
        },
      },
      toolbar: {
        color: undefined,
        dark: undefined,
        light: undefined,
        height: (context) => {
          return context.isMobile ? 54 : 64
        },
        props: {
          flat: true,
        },
        style: {},
      },
      'toolbar-button': {
        colorWhenActive: 'primary',
        colorWhenInactive: undefined,
        style: (context) => (isActive) => ({}),
      },
      'toolbar-items': {
        style: {},
      },
      'toolbar-logo': {
        props: {
          width: 36,
        },
        style: {},
      },
      'toolbar-title': {
        style: {},
      },
    },
    dark: false,
  },
}

module.exports = config
