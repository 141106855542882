export default function (context, inject) {
  const config = {
  "metadatas": {
    "name": "BK La Radio",
    "webappThemeColor": "#FD8631",
    "lang": "fr-FR",
    "charset": "utf-8",
    "colorPrimary": "#FD8631",
    "colorAccent": "#ededed",
    "colorSecondary": "#ff8f00",
    "colorInfo": "#26a69a",
    "colorWarning": "#ffc107",
    "colorError": "#dd2c00",
    "colorSuccess": "#00e676",
    "iconFileName": "icon.png",
    "iconProperties": {},
    "supportEmailAddress": "digital@mediameeting.fr",
    "siteHeadTitle": "BK La Radio",
    "appName": "BK La Radio",
    "webappFonts": {
      "families": {
        "Roboto": [
          300,
          400,
          500,
          700
        ],
        "Merriweather": [
          300,
          400,
          500,
          700
        ]
      },
      "download": true,
      "outputDir": "static/fonts/google",
      "fontsPath": "~static/fonts/google/fonts"
    }
  }
}
  const metadatas = config.metadatas

  inject('metadatas', config.metadatas)
}
