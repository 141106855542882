const $merge = require('lodash.merge')

const config = require('./config.base')
const loadShell = require('./utils/voicerconfig/load-shell')

const configEnvironments = {
  development: require('./config.development'),
  staging: require('./config.staging'),
  production: require('./config.production'),
  next: require('./config.next'),
}

let configLocal = require('./utils/voicerconfig/load-local')
let envConfig = null

if (process.env.ADD_CONFIG_OPTIONS) {
  try {
    console.log(`🤓 use environment options`)
    envConfig = JSON.parse(process.env.ADD_CONFIG_OPTIONS)
  } catch (error) {
    console.error(`Invalid environment options`)
    console.error(`You passed`, process.env.ADD_CONFIG_OPTIONS)
    console.error(error)
    process.exit(1)
  }
}

const transformConfig = (config) => {
  if (config.useShell) {
    loadShell(config)
  }
  return config
}

/**
 * default configuration file
 * common no matter the BROCOLI_API_MODE
 * you can override properties in
 * config.[BROCOLI_API_MODE].js configurations files
 */
const endConfig = $merge(
  {},
  config,
  {
    /**
     * common configuration here
     */
    siteId: '5eecc83cc3fe76767cb1d40c',
    enableExperimentalBlocks: true,
    metadatas: {
      name: 'BK La Radio',
      colorPrimary: '#FD8631',
    },
    analytics: {
      ga: 'G-GHW5ZZTBSN',
      mixpanel: 'bfd8983e7d8e024278356fa9e4f333a4',
      sentry:
        'https://60a05355f36540528d4e4c8cbb35c0dd@o307478.ingest.sentry.io/5319153',
    },
    theme: {
      components: {
        toolbar: {
          color: '#603B31',
          props: { 
            dark: true,
          }
        },
        'toolbar-title': {
          style: {
            color: '#FFFFFF',
          },
        },
      },
    },
    authMode: [
      [
        'local',
        {
          mode: 'unique_password',
          defaultLogin: 'digital@mediameeting.fr',
        },
      ],
    ],
    disabledComponents: ['wToolbarSearch', 'wButtonLike'],
    disablePageListen: true,
    pages: (pager) => {
      return pager
        .remove('podcasts')
        .remove('radios')
        .update('default', {
          options: {
            payload: {
              is: 'live',
            },
            reducer(contents, storeContext) {
              try {
                const contentsArr = contents.toArray()
                storeContext.dispatch('setDefaultLoadedContents', contentsArr)
                const content = require('./helper-rotative-contents').getCurrentContent(
                  contentsArr
                )

                return [content]
              } catch (error) {
                console.error(error)

                return contents.toArray()
              }
            },
          },
        })
    },
    nuxt: {
      router: {
        // destruction complète de la home native
        extendRoutes(routes) {
          return routes.map((route) => {
            if (route.name === 'index') {
              route.component = '~/excentrics/pages/rotative-content-home.vue'
            }
            return route
          })
        },
      },
    },
  },
  configEnvironments[process.env.BROCOLI_API_MODE],
  configLocal,
  envConfig
)

module.exports = transformConfig(endConfig)
