/**
 * configuration applied when BROCOLI_API_MODE = staging
 */
module.exports = {
  authMode: ['local'],
  // ---------------------------------------
  // here some quick examples configurations
  // uncomment below to override default configuration during
  // your developments
  // ---------------------------------------
  //
  // authForceLocaleForDevelopment: true,
  // forceSpokeConfiguration: {
  //   apiMode: {
  //     rest: 'http://localhost:3030',
  //     ws: 'ws://localhost:3033'
  //   }
  // }
}
