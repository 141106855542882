// eslint-disable-next-line prettier/prettier
import {
  mapState,
  mapGetters
} from 'vuex'

export default {
  props: {
    content: {
      type: Object,
      default: () => null,
    },
    icon: {
      type: Boolean,
      default: true,
    },
    props: {
      type: Object,
      default: () => ({
        icon: true,
      }),
    },
    noRedirect: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState({
      playerContentId: (state) => state.player.content.id,
    }),
    ...mapGetters({
      isError: 'player/isError',
      isLoading: 'player/isLoading',
      isPlaying: 'player/isPlaying',
    }),
    isSameContent() {
      const content = this.content

      return content.id === this.playerContentId
    },
    isLoadingContent() {
      return this.isSameContent && this.isLoading === true
    },
    isErrorContent() {
      return this.isSameContent && this.error
    },
    isPlayingContent() {
      return this.isSameContent && this.isLoading === false && this.isPlaying
    },
  },

  methods: {
    handleClick() {
      if (this.isPlayingContent) {
        this.$store.dispatch('player/ctrlPlayer', 'pause')
      } else {
        this.$store.dispatch('player/changePlayerContent', {
          content: this.content,
        })
        this.$store.dispatch('player/ctrlPlayer', {
          action: 'play',
          content: this.content,
        })
      }
    },
  },
}
