/* eslint-disable prettier/prettier */
import $clonedeep from 'lodash.clonedeep'

import AssetImage1 from '~/assets/images/exc-bg-bk-1.png'
import AssetImage2 from '~/assets/images/exc-bg-bk-2.png'
import AssetImage3 from '~/assets/images/exc-bg-bk-3.png'

import AssetImage1Mobile from '~/assets/images/exc-bg-bk-1_mobile.png'
import AssetImage2Mobile from '~/assets/images/exc-bg-bk-2_mobile.png'
import AssetImage3Mobile from '~/assets/images/exc-bg-bk-3_mobile.png'

// trick pour quick / bk
// un sujet de contenus rotatifs est à l'étude sur Brocoli
// rendant obsolète ce repo dans un avenir (incertain)
// oui, c'est faillible ici (on considère que l'heure du nav est UTC+2 (fr))
const CONTENTS_TABLE = [
  {
    slug: 'bk_la_radio_matinale',
    background: AssetImage1,
    backgroundMobile: AssetImage1Mobile,
    nextSlug: 'bk_la_radio_journee',
    activeFn: (currentHour) => {
      return currentHour >= 8 && currentHour < 11
    },
  },
  {
    slug: 'bk_la_radio_journee',
    background: AssetImage2,
    backgroundMobile: AssetImage2Mobile,
    nextSlug: 'bk_la_radio_soir',
    activeFn: (currentHour, currentMinutes) => {
      if (currentHour >= 11 && currentHour < 22) return true
      else if (currentHour === 23 && currentMinutes < 30) return true
      return false
    },
  },
  {
    slug: 'bk_la_radio_soir',
    nextSlug: 'bk_la_radio_matinale',
    background: AssetImage3,
    backgroundMobile: AssetImage3Mobile,
    activeFn: (currentHour, currentMinutes) => {
      if (currentHour >= 0 && currentHour < 8) return true
      else if (currentHour === 22 && currentMinutes >= 30) return true
      return false
    },
    gob: true,
  },
]

export const CONTENTS_DIRECTIVES = [...CONTENTS_TABLE]

export function getCurrentContent(allContents) {
  const findContentFromTable = (element) => {
    const tryStrategy = allContents.find((content) => {
      return content.slug === element.slug
    })

    if (!tryStrategy) {
      return $clonedeep(allContents[0] || {})
    }

    return $clonedeep(tryStrategy)
  }

  const contentActivatorCall = () => {
    const currentTime = new Date()
    const currentHour = currentTime.getHours()
    const currentMinutes = currentTime.getMinutes()

    let content = null

    for (let i = 0; i < CONTENTS_TABLE.length; i++) {
      const table = CONTENTS_TABLE[i]

      if (table.activeFn(currentHour, currentMinutes)) {
        content = table
        break
      }
    }

    if (content) {
      return findContentFromTable(content)
    }
  }

  return contentActivatorCall()
}
